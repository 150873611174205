<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Conservation Type (En)" vid="conservation_type_en" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="conservation_type_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.conservation_type_en')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="crop_name"
                          v-model="formData.type_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Conservation Type (Bn)" vid="conservation_type_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="conservation_type_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.conservation_type_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="conservation_type_bn"
                          v-model="formData.type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                     <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                         <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('germConfig.remarks_en')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="remarks"
                                  v-model="formData.remarks"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Remarks (Bn)"  vid="remarkse_bn" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks_bn"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('germConfig.remarks_bn')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="remarks_bn"
                                  v-model="formData.remarks_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { conservationTypeStore, conservationTypeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getConservationType()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        type_name: '',
        type_name_bn: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      }
    }
  },
  methods: {
    getConservationType () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${conservationTypeUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, conservationTypeStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
